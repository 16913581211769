
import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import { DOMParser } from "xmldom";
import CommentList from "../components/comment-list";
import CommentForm from "../components/comment-form";
import NewsletterForm from "../components/newsletter-form";
import { ofuscarLink } from "../utils.js";
import RelatedPosts from "../components/related-posts";
// import BannerPost from "../components/banner-post" // ara mateix no es necessita (potser més endavant)
// import TopArticles from "../components/top-articles";
import Breadcrumb from "../components/breadcrumb";
const { htmlToText } = require("html-to-text");
const dictionary = require("../dic/dic.json");
// const languageIds = require("../dic/ids.json");

class PostTemplate extends Component {
  constructor (props) {
    super(props);
    this.state = { categoria: null, main_cat_slug: "", main_cat_name: "", breadcrumb: [], bannerPosts: "" };
    this.createSchema = this.createSchema.bind(this);
    this.dataLayerEvents = this.dataLayerEvents.bind(this);
  }

  addScript (url) {
    const script = document.createElement("script");
    script.src = url;
    script.async = false;
    document.body.appendChild(script);
  }

  componentDidMount () {
    // this.addScript("https://code.jquery.com/jquery-3.6.0.min.js");
    this.addScript("/customjs/jquery-ui.min.js");
    this.addScript("/customjs/custom.js");
    this.addScript("/customjs/calculadoras/sueldo-neto.js");

    const post = this.props.data.wpPost;
    let slugPage = post.uri;
    const bannersContent = this.props.data.allWpPage.edges;
    const bannerPosts = document.querySelectorAll(".banner-post");
    const bannerIds = this.props.pageContext.bannerIds;
    bannerPosts.forEach((bannerPost, index) => {
      if (index < bannerIds.length) {
        const bannerFound = bannersContent.filter((bannerContent) => bannerContent.node.databaseId === bannerIds[index]);
        if (bannerFound.length > 0) {
          bannerPost.innerHTML = bannerFound[0].node.page_content.contentRaw;
        }
      }
    });
    const nodepostCat = post.categories.nodes;
    let categoria = this.state.categoria;
    let main_cat_slug = this.state.main_cat_slug;
    let main_cat_name = this.state.main_cat_name;
    // let bannersPosts = this.state.bannerPosts;
    // bannersPosts = bannerFound;
    for (const i in nodepostCat) {
      if (
        nodepostCat[i].ancestors !== undefined &&
        nodepostCat[i].ancestors !== null &&
        nodepostCat[i].databaseId !== 9525 &&
        nodepostCat[i].databaseId !== 9552 &&
        nodepostCat[i].databaseId !== 9528 &&
        nodepostCat[i].databaseId !== 9531 &&
        nodepostCat[i].databaseId !== 9534 &&
        nodepostCat[i].databaseId !== 9537 &&
        nodepostCat[i].databaseId !== 9549 &&
        nodepostCat[i].databaseId !== 9540 &&
        nodepostCat[i].databaseId !== 9543 &&
        nodepostCat[i].databaseId !== 9546
      ) {
        categoria = nodepostCat[i];
      } else if (nodepostCat[i].databaseId === 102 || nodepostCat[i].databaseId === 111) {
        main_cat_slug = nodepostCat[i].slug;
        main_cat_name = nodepostCat[i].name;
      }
    }
    const breadcrumb = [];

    let page = {
      title: main_cat_name,
      slug: main_cat_slug
    };
    breadcrumb.push(page);
    if (categoria !== null) {
      page = {
        title: categoria.name,
        slug: categoria.slug
      };
      breadcrumb.push(page);
    }
    page = {
      title: post.title,
      slug: null
    };
    breadcrumb.push(page);
    this.setState({ main_cat_slug, main_cat_name, categoria, breadcrumb });
    // let checkH3=document.querySelectorAll("h3");
   // if(checkH3[0].classList.contains("p5")){
      const menu=document.querySelector(".index-menu");
    menu.style.visibility="hidden";
      menu.style.opacity="0";
      menu.style.position="absolute";
      let nav = document.querySelectorAll("h2");
      let allNav = "";
    for (const element in nav){
        if(nav[element].classList!==undefined && nav[element].classList.contains("title-section")){
          //do nothing
        }else{
            if(nav[element].textContent!=undefined){
              nav[element].setAttribute("id","id"+element);
              allNav += "<li><a href='#id"+element+"'>"+nav[element].textContent+"</a></li>"
            }
            document.getElementById("menu-titles").innerHTML = allNav;
      }
        }
    /* }else{
      //Modify this else before publish
      let nav = document.querySelectorAll("h2");
      let allNav = "";
      for (const element in nav){
        if(nav[element].textContent!=undefined){
          nav[element].setAttribute("id","id"+element);
          allNav += "<li><a href='#id"+element+"'>"+nav[element].textContent+"</a></li>"
        }
      }
      document.getElementById("menu-titles").innerHTML = allNav;
    }*/


    // RelatedPosts delete las slash
    const relatedPostsWPML = document.querySelectorAll(".rp4wp-related-post-content a");
    relatedPostsWPML.forEach(link => {
      const href = link.getAttribute("href");
      if (href.slice(-1) === "/") {
        console.log(href, href.length);
        link.setAttribute("href", href.substring(0, href.length - 1));
      }
    });
    this.dataLayerEvents(categoria);
  }

  dataLayerEvents (categoria) {
    let categoryName = "";
    if (categoria != null) {
      categoryName = categoria.name;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [{
          item_name: document.querySelector(".innercontentpost h1").textContent, // Name or ID is required.
          item_category: categoryName
        }]
      }
    });
    console.log(window.dataLayer);
  }

  createSchema (seo, post, lang) {
    const nodepostCat = post.categories.nodes;
    let categoria = null;
    for (const i in nodepostCat) {
      if (
        nodepostCat[i].ancestors !== undefined &&
        nodepostCat[i].ancestors !== null &&
        nodepostCat[i].databaseId !== 9525 &&
        nodepostCat[i].databaseId !== 9552 &&
        nodepostCat[i].databaseId !== 9528 &&
        nodepostCat[i].databaseId !== 9531 &&
        nodepostCat[i].databaseId !== 9534 &&
        nodepostCat[i].databaseId !== 9537 &&
        nodepostCat[i].databaseId !== 9549 &&
        nodepostCat[i].databaseId !== 9540 &&
        nodepostCat[i].databaseId !== 9543 &&
        nodepostCat[i].databaseId !== 9546
      ) {
        categoria = nodepostCat[i];
      }
    }
    const appJsonAux = [];
    let urlImage = null;
    if (post.featuredImage !== null &&
      post.featuredImage.node !== null) {
      urlImage = post.featuredImage.node.sourceUrl;
    }
    let html = post.excerpt;
    html = html.replace("[", "");
    html = html.replace("]", "");
    const post_excerpt = htmlToText(html, {
      ignoreHref: true
    });
    const postSchema = {
      "@context": "https://schema.org",
      "@type": "Article",
      name: post.title,
      headline: post.title,
      description: post_excerpt,
      datePublished: post.date,
      dateModified: post.modified
    };
    if (urlImage !== null) {
      postSchema.image = urlImage;
    }

    if (categoria !== null) {
      postSchema.articleSection = categoria.name;
    }
    // afegir url autor
    if (post.author !== null) {
      let authorSchema = {
        "@type": "Person",
        name: post.author.node.name,
        image: post.author.node.avatar.url,
        url: "/" + lang + "/" + post.author.node.slug
      };
      // url
      if (post.author.node.name === "billage") {
        authorSchema = {
          "@type": "Organization",
          name: post.author.node.name,
          logo: "/images/logo-billage-color.svg"
        };
        // url
      }
      postSchema.author = authorSchema;
    }
    const postSchemaString = JSON.stringify(postSchema);
    appJsonAux.push(postSchemaString);
    if (typeof post.content !== "undefined" && post.content !== null) {
      const parser = new DOMParser();
      const domAux = parser.parseFromString(post.content, "text/html");
      let questions = [];
      const nodeList = domAux.childNodes;
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i];
        if (node.tagName !== "h2") continue;
        let nextNode = node.nextSibling;
        if (nextNode.nodeName === "#text") nextNode = nextNode.nextSibling;
        if (nextNode.tagName !== "p") continue;
        if (nextNode.textContent.trim() === "") continue;
        const question = {
          "@type": "Question",
          name: node.textContent,
          acceptedAnswer: {
            "@type": "Answer",
            text: nextNode.textContent
          }
        };
        questions.push(question);
      }
      // var allContent = document.createElement("div");
      // allContent.innerHTML = post.content;
      // let questions = [];
      // allContent.querySelectorAll("h2").forEach((h2node) =>
      //   if(node.tagName !== "H2") return;
      //   let nextNode = node.nextSibling;
      //   if (nextNode.nodeName === "#text") nextNode = nextNode.nextSibling;
      //   if (nextNode.tagName !== "P") return;
      //   const question = {
      //     "@type": "Question",
      //     name: node.textContent,
      //     acceptedAnswer: {
      //       "@type": "Answer",
      //       text: nextNode.innerHTML
      //     }
      //   };
      //   questions.push(question);
      // });
      if (questions.length > 0) {
        const faqsSchema = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: questions
        };
        appJsonAux.push(JSON.stringify(faqsSchema));
      }
    }
    // console.log("JSON", appJsonAux);

    return appJsonAux;
  }

  render () {
    const post = this.props.data.wpPost;
    const lang = this.props.pageContext.idioma;
    const destacados = this.props.data.wpCategory.posts.nodes;
    const seo = post.seo;
    let slugPage = post.uri;
    let app_json = this.createSchema(seo, post, lang);
    // Create faqs from h2

    

    // const alternates = [slugPage];
    // var openGraphslugPage = seo.opengraphUrl;
    /* PUBLICAR POSTS EN CATALÀ I ANGLÉS */
    const alternates = post.translated;
    let openGraphslugPage = seo.opengraphUrl;

    // const bannerContent = this.props.data.wpPage.page_content.contentRaw;
    // const bannerContent = this.state.BannerPost;
    let mainImage = null;
    if (post.featuredImage !== null &&
      post.featuredImage.node !== null &&
      post.featuredImage.node.localFile !== null) {
      mainImage = post.featuredImage.node.localFile.childImageSharp.fluid;
      // mainImage.aspectRatio= 16 / 9;
      mainImage.sizes = "(max-width: 540px) 100px,(max-width: 1024px) 400px, (max-width: 1200px) 300px, 800px";
    }

    if (slugPage !== undefined) {
      slugPage = slugPage.replace("https://www.getbillage.com/wp", "");
      slugPage = slugPage.replace("https://getbillage.com/wp", "");
      slugPage = slugPage.replace("https://www.getbillage.com", "");
      slugPage = slugPage.replace("http://www.getbillage.com/wp", "");
      slugPage = slugPage.replace("http://getbillage.com/wp", "");
      slugPage = slugPage.replace("http://www.getbillage.com", "");
      slugPage = slugPage.replace("/all/", `/${this.state.main_cat_slug}/`);
      slugPage = "/" + lang + "/" + slugPage.substring(4, slugPage.length);
      if (
        slugPage.charAt(slugPage.length - 1) === "/"
      ) {
        slugPage = slugPage.substring(0, slugPage.length - 1);
      }
    }

    openGraphslugPage = "/" + lang + "/" + openGraphslugPage.substring(4, openGraphslugPage.length);

    if (alternates !== undefined) {
      alternates.forEach((alternate) => {
        alternate.uri = alternate.uri.replace("https://getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("https://www.getbillage.com", "");
        alternate.uri = alternate.uri.replace("http://www.getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("http://getbillage.com/wp", "");
        alternate.uri = alternate.uri.replace("http://www.getbillage.com", "");
        const altLang = alternate.locale.locale.substring(0, 2);
        alternate.seo.canonical = "/" + altLang + "/" + alternate.uri.substring(4, alternate.uri.length);
        // alternate.seo.canonical = "/" + altLang + "/" + alternate.seo.canonical.substring(4, alternate.seo.canonical.length)
      });
    }

    const relacionadosImportados =
      this.props.data.wpPost.relacionadosGroup.relacionados !== null
        ? this.props.data.wpPost.relacionadosGroup.relacionados
        : [];

    let colPost = "col-12";
    let colRelated = "d-none";
    let foundDestacados = false;
    if (destacados.length > 0 && lang === "es") {
      foundDestacados = true;
    }
    if (relacionadosImportados.length > 0 || foundDestacados) {
      colPost = "col-12 col-lg-8";
      colRelated = "col-12 col-lg-4";
    }
    // var relacionadosImportados = this.props.data.allWpPost.edges;
    const relacionados = [];
    // console.log("Importados", relacionados);
    for (const i in relacionadosImportados) {
      const relacionadoAux = relacionadosImportados[i];

      let relacionadoImage = null;
      if (relacionadoAux.featuredImage !== null &&
        relacionadoAux.featuredImage.node !== null &&
        relacionadoAux.featuredImage.node.localFile !== null) {
        relacionadoImage = relacionadoAux.featuredImage.node.localFile.childImageSharp.fluid;
        // mainImage.aspectRatio= 16 / 9;
        relacionadoImage.sizes = "(max-width: 1185px) 0px, 300px";
        relacionadoAux.relacionadoImage = relacionadoImage;
      }

      relacionados.push(relacionadoAux);
    }
    const completeUrl = "https://www.getbillage.com" + slugPage;

    return (
      <Layout currentPage={post.title} langPage={lang} alternates={alternates}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugPage}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={post.date}
          dctermsModified={post.modified}
          lang={lang.substring(0, 2)}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
          alternates={alternates}
          appJson={app_json}
        />
        <div
          id="page-content"
          className="post-page"
          vocab="http://schema.org/"
          typeof="BlogPosting"
          resource={slugPage}
        >
          <Breadcrumb props={this.state.breadcrumb} isChild="false" isPage="false" />
          <div className="wrapper wrapper-post container">
            <div className="row">
              <div className={colPost + " postcontent"}>
				  <div className="index-menu">
                    <nav className="menu" >
                      <ul id="menu-titles">

                      </ul>
                    </nav>
                  </div>
                {mainImage !== null
                  ? (
                    <Img itemProp="image"
                      fluid={
                        mainImage
                      }
                      alt={post.featuredImage.node.altText}
                    />
                    )
                  : null}

                <div className="infopost">
                  {post.author !== null
                    ? (

                      <div className="author col-12 center">
                        <div className="gravatar">
                          <img
                            src={post.author.node.avatar.url}
                            alt={post.author.node.name}
                          />
                        </div>
                        {post.author.node.name !== "billage"
                          ? <Link to={`/es/author/${post.author.node.slug}`}>{post.author.node.name}</Link>
                          : <span>{post.author.node.name}</span>
                        }
                      </div>
                      )
                    : null}
                  {this.state.categoria !== null
                    ? (
                      <div className="link-category">
                        <Link to={`/${lang}/${this.state.main_cat_slug}/${this.state.categoria.slug}`}>
                          {this.state.categoria.name}
                        </Link>
                      </div>
                      )
                    : null}
                  <div
                    dangerouslySetInnerHTML={{ __html: post.date }}
                    className="link-date"
                  />
                </div>
                <div className="innercontentpost">
                <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                <hr />
                <aside className="social-share">
                  <ul className="blog-social">
                    <li className="li-facebook">
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={
                          "https://www.facebook.com/sharer.php?u=" + completeUrl
                        }
                        title="Facebook"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li className="li-twitter">
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={
                          "https://twitter.com/share?text='" +
                          post.title +
                          "'&url=" + completeUrl
                        }
                        title="Twitter"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li className="li-linkedin">
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={
                          "https://linkedin.com/shareArticle?mini=true&title='" +
                          post.title +
                          "'&url=" + completeUrl
                        }
                        title="Linkedin"
                      >
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </aside>
                <div className="postbody">

                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                 </div>
                 </div>
                 {/*Old related posts */}
                {/* <RelatedPosts tags={post.tags.nodes} lang={lang} postId={post.databaseId}/> */}
                <NewsletterForm lang={lang} />

                <div className="postlower">
                  <section className="commentsection" id="comentarios">
                    <CommentList
                      postId={post.id}
                      comments={post.comments}
                      lang={lang}
                    />
                    <CommentForm postId={post.databaseId} lang={lang} />
                  </section>
                  <footer className="backtotop">
                    <a href="#">
                      <i className="fa fa-chevron-circle-up"></i>
                    </a>
                  </footer>
                </div>
              </div>
              <div className={colRelated + " columnalatpost"}>
                <div className="sidebar">
                  {/* <div className="banner-post"></div>
                   <div className="widget main_navigation">
                    <BannerPost content={bannerFound} />
                  </div> */}
                  <div className="widget main_navigation">
                    <Link
                      to={`/${lang}/`}
                    >
                      <img src="https://www.getbillage.com/wp/wp-content/uploads/2022/10/cta-lateral-blue.svg" alt="cta lateral" className="alignnone size-full wp-image-38855" width="300" height="480" />
                    </Link>
                  </div>
                  <section className="widget relatedposts">
                    {relacionados.length > 0
                      ? (
                        <big className="title-section">
                          {dictionary[lang].title_destacados}
                        </big>
                        )
                      : null}
                    {relacionados.length > 0
                      ? relacionados.map((relacionado, i) => {
                        return (
                          <article key={i}>
                            <span
                              onClick={ofuscarLink}
                              data-url={`/${lang}/blog/${relacionado.slug}`}
                              className="col-4 feature ofuscado"
                            >{relacionado.relacionadoImage !== null
                              ? (
                                  <Img itemProp="image"
                                    fluid={
                                      relacionado.relacionadoImage
                                    }
                                  />
                                )
                              : null}
                            </span>
                            <span className="col-8 title">
                              <Link
                                to={`/${lang}/blog/${relacionado.slug}`}
                              >{`${relacionado.title}`}</Link>
                            </span>
                          </article>
                        );
                      })
                      : null}
                  </section>
                  {/*destacados.length > 0 && lang === "es"
                    ? (
                      <section className="widget latestposts">
                        <TopArticles
                          destacados={destacados}
                          lang={lang}
                          categoria={"blog"}
                        />
                      </section>
                    )
                    : null*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query currentPostQuery(
    $id: String!
    $bannerIds: [Int]!
    $relatedImportedPosts: [Int]!
  ) {
    wpPost(id: { eq: $id }) {
      title
      id
      databaseId
      content
      date(formatString: "D/MM/YYYY")
      modified
      uri
      excerpt
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      categories {
        nodes {
          databaseId
          name
          slug
          ancestors {
            nodes {
              slug
              name
            }
          }
        }
      }
      translated {
        slug
        uri
        locale{
          locale
        }
        seo {
          canonical
        }
      }
      featuredImage {
        node {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            url
          }
        }
      }
      comments {
        nodes {
          content
          date
          databaseId
          author {
            node {
              name
              email
              url
            }
          }
        }
      }
      author {
        node {
          name
          slug
          avatar {
            url
          }
        }
      }
      tags {
        nodes {
          name
          posts {
            nodes {
              databaseId
              title
              excerpt
              slug
              date(formatString: "DD/MM/yyyy")
              seo {
                title
                metaDesc
              }
              author {
                node {
                  name
                  slug
                  avatar {
                    url
                  }
                }
              }
              categories {
                nodes {
                  databaseId
                  name
                  slug
                  ancestors {
                    nodes {
                      databaseId
                      slug
                      uri
                      name
                    }
                  }
                }
              }
              featuredImage {
                node {
                  altText
                  srcSet
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      relacionadosGroup {
        relacionados {
          ... on WpPost {
            id
            title
            slug
            featuredImage {
              node {
                srcSet
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid{
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            categories {
              nodes {
                slug
                databaseId
                ancestors {
                  nodes {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpPost(filter: { databaseId: { in: $relatedImportedPosts } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          featuredImage {
            node {
              srcSet
              sourceUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          categories {
            nodes {
              databaseId
              name
              slug
              ancestors {
                nodes {
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }

    allWpPage(filter: {databaseId: {in: $bannerIds } }) {
      edges {
        node {
          databaseId
          page_content {
            contentRaw
          }
        }
      }
    }

    wpCategory(databaseId: { eq: 6462 }) {
      databaseId
      name
      slug
      posts {
        nodes {
          databaseId
          title
          slug
          featuredImage {
            node {
              srcSet
              sourceUrl
              localFile {
                childImageSharp {
                  fluid{
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
