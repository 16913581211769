import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { ofuscarLink } from "../utils.js";
const dictionary = require("../dic/dic.json");
// include styles

class RelatedPosts extends React.Component {
  render () {
    const tags = this.props.tags;
    console.log("TAGS", tags);
    const relatedPostsAux = [];
    tags.forEach((tag) => {
      tag.posts.nodes.forEach((post) => {
        if (!post.categories.nodes.find(({ slug }) => slug === "blog")) return;
        const found = relatedPostsAux.find(({ databaseId }) => databaseId === post.databaseId);
        if (!found) {
          if (post.databaseId === this.props.postId) return;
          const relacionadoAux = post;
          let relacionadoImage = null;
          if (relacionadoAux.featuredImage !== null &&
            relacionadoAux.featuredImage.node !== null &&
            relacionadoAux.featuredImage.node.localFile !== null) {
            relacionadoImage = relacionadoAux.featuredImage.node.localFile.childImageSharp.fluid;
            // mainImage.aspectRatio= 16 / 9;
            relacionadoImage.sizes = "(max-width: 1185px) 0px, 300px";
            relacionadoAux.relacionadoImage = relacionadoImage;
          }
          relacionadoAux.random = false;
          relatedPostsAux.push(relacionadoAux);
        }
      });
    });
    const relatedPosts = [];
    for (let i = 0; i < 6; i++) {
      if (i >= relatedPostsAux.length) break;
      const randomRelated = Math.floor(Math.random() * relatedPostsAux.length);
      if (relatedPostsAux[randomRelated].random) { i--; continue; }
      relatedPosts.push(relatedPostsAux[randomRelated]);
      relatedPostsAux[randomRelated].random = true;
    }
    return (
        <div id="recommended">
        {relatedPosts.length > 0
          ? <div className="recomendamos">
            <h2 className="title-section">
              {dictionary[this.props.lang].te_recomendamos}
            </h2>
            <div className="row post-list">
                {relatedPosts.map((relacionado, i) => {
                  return (
                  <article key={i} className=" post-wrapper col-12 col-md-6 col-lg-4">
                    <span
                      onClick={ofuscarLink}
                      data-url={`/${this.props.lang}/blog/${relacionado.slug}`}
                      className="feature ofuscado"
                    >
                      {relacionado.relacionadoImage !== null
                        ? (
                          <Img itemProp="image"
                            fluid={
                              relacionado.relacionadoImage
                            }
                          />
                          )
                        : null}
                    </span>
                    <span className="title">
                      <Link
                        to={`/${this.props.lang}/blog/${relacionado.slug}`}
                      >{`${relacionado.title}`}</Link>
                    </span>
                  </article>
                  );
                })}
              </div>
            </div>
          : null}
        </div>
    );
  }
}

export default RelatedPosts;
