import React from "react"

const dictionary = require("../dic/dic.json")

// Our main component class.
class NewsletterForm extends React.Component {
	constructor(props) {
		super(props)
		// initial state.
		this.state = {
			newsletter_email: "",
			submited: false
		}

		// Bind input changes.
		this.handleInputChange = this.handleInputChange.bind(this)
	}

	// Handles input change events.
	handleInputChange(event) {
		this.setState({
			[event.target.id]: event.target.value,
		})
	}

	// handle submit
	handleSubmit(e) {
		// Prevent default form submit behavior.
		e.preventDefault()

		// this.setState({ formStatus: "loading" })
		const form = e.target;
		const [newsletter_email] = form.elements;

		let formData = new FormData();
		formData.append("email", newsletter_email.value);
		formData.append("name", "Usuario web (" + newsletter_email.value + ")");
		formData.append("event_name", "registro-newsletter");

		fetch("/php/intercom.php", {
			// fetch("https://www.getbillage.com/php/intercom.php", {
			method: 'POST',
			body: formData
		}).then((_) => {
			//console.log(response);
			this.setState({ submited: true });
		}).catch(e => {
			console.log(e);
		});
	}

	handleServerResponse(event) {
		this.setState({
			[event.target.id]: event.target.value,
		})
	}


	// Render the form.
	render() {
		const legal = dictionary[this.props.lang].contacto.acepto_condiciones

		return (
			<form
				id="subscribe-footer-post"
				method="post"
				className="subscribe-form avia_ajax_form"
				onSubmit={(e) => this.handleSubmit(e)}
			>
				<span className="subscribe-title">{dictionary[this.props.lang].newsletter_title}</span>

				{this.state.submited ? (
					<p className="form-ok">{dictionary[this.props.lang].newsletter_confirmation}</p>
				) :
					(<div className="form-contenido">
						<div className="form-register form-newsletter">
							<div className="input-group comboGroup">
								<input className="form-control"
									type="email"
									id="newsletter_email"
									name="newsletter_email"
									placeholder={dictionary[this.props.lang].newsletter_email}
									required
									value={this.state.newsletter_email}
									onChange={this.handleInputChange}
								/>
								<span className="input-group-addon">
									<button className="boton boton-newsletter" type="submit">{dictionary[this.props.lang].newsletter_button}</button>
								</span>
							</div>
						</div>
						<div
							id="condiciones_newsletter"
							className="form_element condiciones texto-legal"
						>
							<label
								className="checkbox-inline"
								htmlFor="acepto_condiciones_newsletter"
							>
								<input
									id="acepto_condiciones_newsletter"
									name="acepto_condiciones_newsletter"
									value="1"
									type="checkbox"
									required
								/>
								<span dangerouslySetInnerHTML={{ __html: legal }} />
							</label>
						</div>
					</div>)}
			</form>

		)
	}
}

export default NewsletterForm
